<template> 
    <Provider v-bind="provider_info"/>
</template>

<script>
import Provider from "@/components/Provider.vue";
export default {
    name: "Guy Fenske",
    components: {
        Provider
    },
    data() {
        return {
            provider_info: {
                name: "Guy Fenske",
                img_src: "photo-coming-soon.jpg",
                biography: [ 
                    "Dr. Guy Fenske grew up on a farm and ranch in Sargent, NE and graduated from Sargent High School in 2012. Throughout his youth, he actively participated in several outdoor and athletic activities. He still reminisces his days playing football, basketball, track, and baseball.",
                    "He continued to pursue his academic and athletic career at the University of Nebraska-Lincoln. While studying Nutrition Exercise Health Science, he also dedicated much of his time to the college track and field team as a heptathlete/decathlete. Throughout his career, he was fortunate to win the Big 10 Indoor and Outdoor Championships several times alongside his teammates.",
                    "Following graduation from UNL, he moved with his wife Alexandria Fenske to Kansas City to attend Cleveland University to attain his chiropractic degree. He graduated in December 2019, and they moved back to their roots in Nebraska. They thoroughly enjoy every minute of being close to family, developing their home, and establishing new relationships in the community.",
                    "Outside the office, he can’t spend enough time outdoors hunting, fishing, and working on projects around their acreage.",
                    "He has a keen fascination with the human body and a passion for helping people live healthier lives. His special interests in medicine include back pain, neck pain, soft tissue injuries, active rehabilitation, pregnancy, children and babies. He looks forward to helping you achieve your life goals through chiropractic care!"
                ],
                acceptsNewPatients: true
            }
        }
    }
}
</script>

<style scoped>

</style>